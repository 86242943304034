import {DIV_CODE_SAF} from "constants/divisionDetails";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {mandateActionItems} from "../utils";
import MandateReversalModal from "./actionModals/MandateReversalModal";
import ResponseModal from "./ResponseModal";

const MandateModalToShow = ({
  modal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const navigate = useNavigate();
  const {division, country} = useParams();
  const IS_DISABLED = false;

  useEffect(() => {
    if (
      modal &&
      modal === DIV_CODE_SAF + mandateActionItems.GENERATE_DOCUMENT
    ) {
      setResponseHeader("Select document type");
      setResponseBody(
        "Choose the document type to generate the outgoing document with.",
      );
      setResponseFooter([
        {
          id: 1,
          text: "Generate PoC",
          buttonClassName: IS_DISABLED
            ? "!bg-gray-300 !text-gray-500 !cursor-not-allowed !opacity-50"
            : "!text-[#111]",
          action: () => {
            navigate(
              appendParamsToUrl(
                "/mandate-certificate",
                [
                  country,
                  division,
                  "poc",
                  rowData?.original?.outboundRecord?.mandateAllocationEuId,
                ],
                {
                  location: currentFilterValues.mbLocationGroupName,
                  period: currentFilterValues.periodName,
                  outboundType: currentFilterValues.outboundTypeName,
                },
              ),
            );
            setModal("");
          },
          disabled: IS_DISABLED,
        },
        {
          id: 2,
          text: "Generate PTD",
          buttonClassName: "!text-[#111]",
          action: () => {
            navigate(
              appendParamsToUrl(
                "/mandate-certificate",
                [
                  country,
                  division,
                  "ptd",
                  rowData?.original?.outboundRecord?.mandateAllocationEuId,
                  rowData?.original?.outboundRecord?.allocationGroupId,
                ],
                {
                  location: currentFilterValues.mbLocationGroupName,
                  period: currentFilterValues.periodName,
                  outboundType: currentFilterValues.outboundTypeName,
                },
              ),
            );
            setModal("");
          },
        },
      ]);
    }
  }, [
    country,
    currentFilterValues.mbLocationGroupName,
    currentFilterValues.outboundTypeName,
    currentFilterValues.periodName,
    division,
    modal,
    navigate,
    rowData?.original?.outboundRecord?.allocationGroupId,
    rowData?.original?.outboundRecord?.mandateAllocationEuId,
    setModal,
    IS_DISABLED,
  ]);

  const renderModal = () => {
    switch (modal) {
      case DIV_CODE_SAF + mandateActionItems.GENERATE_DOCUMENT:
        return (
          <ResponseModal
            showModal={modal}
            header={responseHeader}
            body={responseBody}
            footerArr={responseFooter}
          />
        );
      default:
        return (
          <MandateReversalModal
            modal={modal}
            setModal={setModal}
            rowData={rowData}
            divisionCode={divisionCode}
            currentFilterValues={currentFilterValues}
          />
        );
    }
  };
  return <>{renderModal()}</>;
};

MandateModalToShow.propTypes = {
  modal: PropTypes.string,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default MandateModalToShow;
