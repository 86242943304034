import {useQuery} from "@apollo/client";
import {Add16} from "@bphxd/ds-core-react/lib/icons";
import {COUNTRY_SPAIN, COUNTRY_GERMANY} from "constants/countryDetails";
import {DIV_CODE_COPRO, DIV_CODE_SAF} from "constants/divisionDetails";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster";
import {GET_PRODUCT_DEFAULT_VALUES} from "graphql/docManager/bioLcDocManagerGetProductDefaultValues";
import Feature from "modules/common/FeatureFlag/Feature";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import moment from "moment";
import process from "process";
import PropTypes from "prop-types";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {
  getDDVNumberInputPattern,
  getDDVTDVNumberInputPattern,
  getNumberInputPattern,
  handleNumber,
  handleNumberAndDDV,
  handleNumberDDVAndTDV,
  parseAndRoundToThreeDecimal,
} from "utils/numberUtil";
import CheckboxFlag from "../CheckboxNullable";
import Datepicker from "../Datepicker";
import GHGSplit from "./GHGSplit";
import SplitInputAs from "./SplitInputAs";
import {parseFormattedGhgValues} from "./utils";

const GHGEmissionInfo = ({
  countries,
  status,
  selectedCountry,
  isDisabled,
  divisionCode,
  fields,
  remove,
  isCheckedYes,
  setIsCheckedYes,
  createAdditionalSplit,
  splitInputAsQty,
  setSplitInputAsQty,
  useSplitMode,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const hideLtpButton =
    process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;
  const docManagerSplitsPecentageFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DOC_MANAGER_SPLITS_PERCENTAGE,
  );
  const {data, loading} = useQuery(GET_PRODUCT_DEFAULT_VALUES);

  const {data: materialData, loading: materialLoading} =
    useQuery(MATERIAL_MASTER);

  const {
    register,
    formState: {errors},
    setError,
    control,
    watch,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useFormContext();

  const [configCenterTdv, setConfigCenterTdv] = useState(null);

  const rawMaterial = watch("rawMaterialType");
  const flagValue = watch("defaultValueAppliedFlag");
  const materialDispatchDatez = watch("materialDispatchDatez");

  const [materialIdByLongName, setMaterialIdByLongName] = useState({});

  useEffect(() => {
    if (!materialLoading) {
      const temp = {};
      materialData?.bioLcCoproConfigCenterMaterialMaster.Records.forEach(
        (i) => {
          temp[i.material_long_name] = i.material_id;
        },
      );
      setMaterialIdByLongName(temp);
    }
  }, [materialLoading, materialData, setMaterialIdByLongName]);

  const [tdvItems, setTdvItems] = useState([]);
  useEffect(() => {
    if (!loading && !materialLoading) {
      const materialDispatchDate = moment(materialDispatchDatez);

      setTdvItems(
        data?.bioLcDocManagerGetProductDefaultValues?.productDefaultValuesData?.filter(
          (i) => {
            if (i.defaultValueType !== "TDV") return false;
            if (i.materialId !== materialIdByLongName?.[rawMaterial])
              return false;
            if (moment(i.validFrom) > materialDispatchDate) return false;
            if (moment(i.validTo) < materialDispatchDate) return false;
            return true;
          },
        ),
      );
    }
  }, [
    loading,
    data,
    setTdvItems,
    materialIdByLongName,
    materialLoading,
    materialDispatchDatez,
    rawMaterial,
  ]);

  const handleLastSplit = (index) => {
    const numOfSplits = fields.length ?? 0;
    if (
      !getValues(`splitDetails.${numOfSplits - 2}.feedstockQty`) ||
      !getValues(`splitDetails.${numOfSplits - 2}.splitPercentage`)
    )
      return;
    let splitsQtySum = 0;
    let splitsPercentageSum = 0;

    for (let i = 0; i < numOfSplits - 1; i += 1) {
      splitsQtySum += convertToNumber(
        getValues(`splitDetails.${i}.feedstockQty`),
        decimalFormat,
      );
      splitsPercentageSum += convertToNumber(
        getValues(`splitDetails.${i}.splitPercentage`),
        decimalFormat,
      );
    }

    const lastSplitQty = formatNumber(
      parseAndRoundToThreeDecimal(
        convertToNumber(getValues("feedstockQty"), decimalFormat) -
          splitsQtySum,
      ),
      decimalFormat,
      0,
    );
    const lastSplitPercentage = formatNumber(
      parseAndRoundToThreeDecimal(100 - splitsPercentageSum),
      decimalFormat,
      0,
    );

    trigger(`splitDetails.${index}.feedstockQty`);
    trigger(`splitDetails.${index}.splitPercentage`);

    setValue(`splitDetails.${numOfSplits - 1}.feedstockQty`, lastSplitQty);
    setValue(
      `splitDetails.${numOfSplits - 1}.splitPercentage`,
      lastSplitPercentage,
    );
    trigger(`splitDetails.${numOfSplits - 1}.splitPercentage`);
    trigger(`splitDetails.${numOfSplits - 1}.feedstockQty`);
  };

  const handleRemoveSplit = (index) => {
    if (fields.length === 0) return;
    if (fields.length === 2) {
      remove([0, 1]);
      return;
    }
    if (docManagerSplitsPecentageFeatureFlag) {
      if (index === 0) {
        setValue(
          "splitDetails.1.feedstockQty",
          formatNumber(
            parseAndRoundToThreeDecimal(
              convertToNumber(
                getValues("splitDetails.1.feedstockQty"),
                decimalFormat,
              ) +
                convertToNumber(
                  getValues("splitDetails.0.feedstockQty"),
                  decimalFormat,
                ),
            ),
            decimalFormat,
            0,
          ),
        );
        setValue(
          "splitDetails.1.splitPercentage",
          formatNumber(
            parseAndRoundToThreeDecimal(
              convertToNumber(
                getValues("splitDetails.1.splitPercentage"),
                decimalFormat,
              ) +
                convertToNumber(
                  getValues("splitDetails.0.splitPercentage"),
                  decimalFormat,
                ),
            ),
            decimalFormat,
            0,
          ),
        );
      } else {
        setValue(
          `splitDetails.${index - 1}.feedstockQty`,
          formatNumber(
            parseAndRoundToThreeDecimal(
              convertToNumber(
                getValues(`splitDetails.${index - 1}.feedstockQty`),
                decimalFormat,
              ) +
                convertToNumber(
                  getValues(`splitDetails.${index}.feedstockQty`),
                  decimalFormat,
                ),
            ),
            decimalFormat,
            0,
          ),
        );
        setValue(
          `splitDetails.${index - 1}.splitPercentage`,
          formatNumber(
            parseAndRoundToThreeDecimal(
              convertToNumber(
                getValues(`splitDetails.${index - 1}.splitPercentage`),
                decimalFormat,
              ) +
                convertToNumber(
                  getValues(`splitDetails.${index}.splitPercentage`),
                  decimalFormat,
                ),
            ),
            decimalFormat,
            0,
          ),
        );
      }
    }
    remove(index);
  };
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const splitDetailsField = watch("splitDetails");
  const changes = useWatch({
    name: [
      "ghgEec",
      "ghgEl",
      "ghgEp",
      "ghgEtd",
      "ghgEu",
      "ghgEsca",
      "ghgEccs",
      "ghgEccr",
      "defaultValueAppliedFlag",
    ],
  });

  const numberInputPattern = useMemo(
    () => getNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  const ddvNumberInputPattern = useMemo(
    () => getDDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  const tdvddvNumberInputPattern = useMemo(
    () => getDDVTDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  useEffect(() => {
    const parsedChanges = parseFormattedGhgValues(changes, decimalFormat);
    const total =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);

    if (flagValue) {
      setIsCheckedYes(true);

      clearErrors("ghgTotal");
      clearErrors("ghgTotalTdv");
      clearErrors("ghgNoValue");

      if (tdvItems.length !== 1) {
        setError("ghgTotalTdv", {
          message:
            "Please double check the document, if all appears in order please contact BioVerse team.",
        });
        setValue("ghgTotal", "No TDV available for the selected raw material.");
      } else if (decimalFormat === "European Style") {
        setConfigCenterTdv(tdvItems[0].defaultValue.replace(".", ","));
        setValue("ghgTotal", configCenterTdv);
      } else {
        setConfigCenterTdv(tdvItems[0].defaultValue.replace(",", "."));
        setValue("ghgTotal", configCenterTdv);
      }
    } else {
      setValue(
        "ghgTotal",
        formatNumber(parseFloat(total.toFixed(3) ?? 0), decimalFormat, 0),
      );
      clearErrors("ghgTotalTdv");
      setIsCheckedYes(false);
    }
  }, [
    changes,
    setValue,
    setError,
    clearErrors,
    decimalFormat,
    flagValue,
    tdvItems,
    setIsCheckedYes,
    setConfigCenterTdv,
    configCenterTdv,
  ]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      {fields?.length === 0 && (
        <>
          {selectedCountry === COUNTRY_GERMANY &&
            divisionCode === DIV_CODE_SAF && (
              <Feature flag={FEATURE_FLAGS.DOC_MAN_DE_SAF_CHANGES}>
                <FormGroup>
                  <Label
                    for="ghgEmissionsRequirementFlag"
                    className="fw-normal"
                  >
                    ISCC PLUS (205-01) GHG-Emissions Requirements
                  </Label>
                  <CheckboxFlag
                    id="ghgEmissionsRequirementFlag"
                    name="ghgEmissionsRequirementFlag"
                    control={control}
                    error={errors.ghgEmissionsRequirementFlag}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Feature>
            )}
          <FormGroup>
            <Label for="defaultValueAppliedFlag" className="fw-normal">
              Total default value according to RED II applied
            </Label>
            <CheckboxFlag
              id="defaultValueAppliedFlag"
              name="defaultValueAppliedFlag"
              control={control}
              error={errors.defaultValueAppliedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          {!isCheckedYes && (
            <>
              <div className="flex flex-row justify-between items-start gap-3 overflow-x-scroll w-full">
                <FormGroup>
                  <Label for="ghgEec" className="fw-normal">
                    Eec
                  </Label>
                  <Input
                    type="text"
                    id="ghgEec"
                    data-test="ghgEec"
                    {...computeProps("ghgEec", numberInputPattern)}
                    invalid={!!errors.ghgEec && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEec && (
                    <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEl" className="fw-normal">
                    + El
                  </Label>
                  <Input
                    type="text"
                    id="ghgEl"
                    data-test="ghgEl"
                    {...computeProps("ghgEl", numberInputPattern)}
                    invalid={!!errors.ghgEl && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEl && (
                    <FormFeedback>{errors.ghgEl?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEp" className="fw-normal">
                    + Ep
                  </Label>
                  <Input
                    type="text"
                    id="ghgEp"
                    data-test="ghgEp"
                    {...computeProps("ghgEp", numberInputPattern)}
                    invalid={!!errors.ghgEp && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEp && (
                    <FormFeedback>{errors.ghgEp?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEtd" className="fw-normal">
                    + Etd
                  </Label>
                  <Input
                    type="text"
                    id="ghgEtd"
                    data-test="ghgEtd"
                    {...computeProps("ghgEtd", ddvNumberInputPattern)}
                    invalid={!!errors.ghgEtd && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumberAndDDV(e)}
                    className="!w-[93px]"
                  />
                  {errors.ghgEtd && (
                    <FormFeedback>{errors.ghgEtd?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEu" className="fw-normal">
                    + Eu
                  </Label>
                  <Input
                    type="text"
                    id="ghgEu"
                    data-test="ghgEu"
                    {...computeProps("ghgEu", numberInputPattern)}
                    invalid={!!errors.ghgEu && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEu && (
                    <FormFeedback>{errors.ghgEu?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEsca" className="fw-normal">
                    - Esca
                  </Label>
                  <Input
                    type="text"
                    id="ghgEsca"
                    data-test="ghgEsca"
                    {...computeProps("ghgEsca", numberInputPattern)}
                    invalid={!!errors.ghgEsca && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEsca && (
                    <FormFeedback>{errors.ghgEsca?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEccs" className="fw-normal">
                    - Eccs
                  </Label>
                  <Input
                    type="text"
                    id="ghgEccs"
                    data-test="ghgEccs"
                    {...computeProps("ghgEccs", numberInputPattern)}
                    invalid={!!errors.ghgEccs && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEccs && (
                    <FormFeedback>{errors.ghgEccs?.message}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="ghgEccr" className="fw-normal">
                    - Eccr
                  </Label>
                  <Input
                    type="text"
                    id="ghgEccr"
                    data-test="ghgEccr"
                    {...computeProps("ghgEccr", numberInputPattern)}
                    invalid={!!errors.ghgEccr && !isDisabled}
                    disabled={isDisabled}
                    onKeyDown={(e) => handleNumber(e)}
                    className="!w-[93px]"
                    maxLength={20}
                  />
                  {errors.ghgEccr && (
                    <FormFeedback>{errors.ghgEccr?.message}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              {errors.ghgNoValue && (
                <FormFeedback className="!mt-[-15px] !block">
                  {errors.ghgNoValue.message}
                </FormFeedback>
              )}
            </>
          )}
          <FormGroup>
            <Label for="ghgTotal" className="fw-normal">
              GHG (total){" "}
              {divisionCode === DIV_CODE_COPRO ? "kgCO2/dry-t" : "gCO2eq/MJ"}
            </Label>
            <Input
              type="text"
              id="ghgTotal"
              data-test="ghgTotal"
              {...computeProps("ghgTotal", {
                required: "Please enter total GHG emissions",
                ...tdvddvNumberInputPattern,
              })}
              invalid={
                (!!errors.ghgTotal && !isDisabled) ||
                (!!errors.ghgTotalTdv && flagValue)
              }
              disabled={isDisabled || isCheckedYes}
              onKeyDown={(e) => handleNumberDDVAndTDV(e)}
              maxLength={20}
            />
            {errors.ghgTotal && !errors.ghgTotalTdv && (
              <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
            )}
            {errors.ghgTotalTdv && (
              <FormFeedback>{errors.ghgTotalTdv?.message}</FormFeedback>
            )}
            {getValues("defaultValueAppliedFlag") && !errors.ghgTotalTdv && (
              <span className="text-xs text-gray-700">
                TDV for {rawMaterial} has been applied
              </span>
            )}
          </FormGroup>
          {divisionCode === DIV_CODE_SAF && (
            <FormGroup>
              <Label for="ghgEmissionPercentageIn" className="fw-normal mb-4">
                GHG emission saving (%)
              </Label>
              <Input
                type="text"
                id="ghgEmissionPercentageIn"
                data-test="ghgEmissionPercentageIn"
                {...computeProps("ghgEmissionPercentageIn", {
                  required: "Please enter GHG emission savings",
                  ...tdvddvNumberInputPattern,
                })}
                invalid={!!errors.ghgEmissionPercentageIn}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumberDDVAndTDV(e)}
                maxLength={20}
              />
              <span className="text-xs text-gray-700">
                Biofuels for transport
              </span>
              {errors.ghgEmissionPercentageIn && (
                <FormFeedback className="mt-2">
                  {errors.ghgEmissionPercentageIn?.message}
                </FormFeedback>
              )}
            </FormGroup>
          )}
          {selectedCountry === COUNTRY_GERMANY &&
            divisionCode === DIV_CODE_SAF && (
              <Feature flag={FEATURE_FLAGS.DOC_MAN_DE_SAF_CHANGES}>
                <FormGroup>
                  <Label for="consumablesFlag" className="fw-normal">
                    ISCC PLUS (205-02) Consumables
                  </Label>
                  <CheckboxFlag
                    id="consumablesFlag"
                    name="consumablesFlag"
                    control={control}
                    error={errors.consumablesFlag}
                    disabled={isDisabled}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="nonGmoFoodFlag" className="fw-normal">
                    ISCC PLUS (205-03) Non-GMO Food / Feed
                  </Label>
                  <CheckboxFlag
                    id="nonGmoFoodFlag"
                    name="nonGmoFoodFlag"
                    control={control}
                    error={errors.nonGmoFoodFlag}
                    disabled={isDisabled}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="nonGmoTechMarketFlag" className="fw-normal">
                    ISCC PLUS (205-04) Non-GMO Technical Markets
                  </Label>
                  <CheckboxFlag
                    id="nonGmoTechMarketFlag"
                    name="nonGmoTechMarketFlag"
                    control={control}
                    error={errors.nonGmoTechMarketFlag}
                    disabled={isDisabled}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="electricityHeatFlag" className="fw-normal">
                    SCC PLUS (205-06) Electricity and Heat from Biogas Plants
                  </Label>
                  <CheckboxFlag
                    id="electricityHeatFlag"
                    name="electricityHeatFlag"
                    control={control}
                    error={errors.electricityHeatFlag}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Feature>
            )}

          {selectedCountry === COUNTRY_SPAIN && hideLtpButton !== "false" && (
            <FormGroup>
              <Label for="physicalReceiptDate" className="fw-normal">
                Date of physical receipt
              </Label>
              <Datepicker
                id="physicalReceiptDate"
                name="physicalReceiptDate"
                control={control}
                error={errors.physicalReceiptDate}
                placeholder="Please add physical receipt date"
                disabled={isDisabled || (splitDetailsField?.length ?? 0) > 0}
                rules={{
                  required: "Please add physical receipt date",
                }}
              />
              {errors.physicalReceiptDate &&
                (splitDetailsField?.length ?? 0) === 0 && (
                  <FormFeedback>
                    {errors.physicalReceiptDate?.message}
                  </FormFeedback>
                )}
            </FormGroup>
          )}
        </>
      )}
      <Feature flag={FEATURE_FLAGS.DOC_MANAGER_SPLITS_PERCENTAGE}>
        {fields?.length > 0 && useSplitMode && (
          <SplitInputAs
            setSplitInputAsQty={setSplitInputAsQty}
            isDisabled={isDisabled}
          />
        )}
      </Feature>
      {fields.map((field, index) => (
        <div key={field.id} className="bg-white p-5">
          <GHGSplit
            field={field}
            index={index}
            countries={countries}
            onRemoveSplit={handleRemoveSplit}
            status={status}
            isCheckedYes={isCheckedYes}
            isDisabled={isDisabled}
            tdvItems={tdvItems}
            configCenterTdv={configCenterTdv}
            setConfigCenterTdv={setConfigCenterTdv}
            rawMaterial={rawMaterial}
            divisionCode={divisionCode}
            isLastSplit={fields.length === index + 1}
            handleLastSplit={handleLastSplit}
            splitInputAsQty={splitInputAsQty}
            useSplitMode={useSplitMode}
          />
          <hr className="border-1 mt-4"></hr>
        </div>
      ))}
      {!isDisabled && fields?.length === 0 && <hr className="border-1"></hr>}
      {!isDisabled &&
        selectedCountry === COUNTRY_SPAIN &&
        divisionCode === DIV_CODE_COPRO && (
          <div>
            <Button
              size="xs"
              onClick={createAdditionalSplit}
              color="standard-primary"
              className="primary-btn rounded-0"
            >
              <Add16 className="btn-icon-prefix" />
              {fields.length === 0 ? "Create split" : "Create additional split"}
            </Button>
          </div>
        )}
      {selectedCountry === COUNTRY_SPAIN && (
        <>
          <FormGroup className="flex flex-col">
            <Label for="disaggregatedDefaultValueOilFlag" className="fw-normal">
              Disaggregated default value for oil extraction only is applied
            </Label>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                type="checkbox"
                id="disaggregatedDefaultValueOilFlag"
                data-test="disaggregatedDefaultValueOilFlag"
                {...computeProps("disaggregatedDefaultValueOilFlag")}
                invalid={
                  !!errors.disaggregatedDefaultValueOilFlag && !isDisabled
                }
                disabled={isDisabled}
              />
              <Label for="disaggregatedDefaultValueOilFlag">Yes</Label>
            </FormGroup>
          </FormGroup>
          <FormGroup className="flex flex-col">
            <Label
              for="disaggregatedDefaultValueSoiln2oFlag"
              className="fw-normal"
            >
              Disaggregated default value for soil N2O emissions is applied
            </Label>
            <FormGroup
              check
              inline
              className="form-check-alt form-check-lg !mb-0"
            >
              <Input
                type="checkbox"
                id="disaggregatedDefaultValueSoiln2oFlag"
                data-test="disaggregatedDefaultValueSoiln2oFlag"
                {...computeProps("disaggregatedDefaultValueSoiln2oFlag")}
                invalid={
                  !!errors.disaggregatedDefaultValueSoiln2oFlag && !isDisabled
                }
                disabled={isDisabled}
              />
              <Label for="disaggregatedDefaultValueSoiln2oFlag">Yes</Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label for="bonusCo245gManureUsedFlag" className="fw-normal">
              Esca: Bonus of 45g CO2eq/MJ manure in the case animal manure is
              used as substrate for the production of biogas and biomethane
            </Label>
            <CheckboxFlag
              id="bonusCo245gManureUsedFlag"
              name="bonusCo245gManureUsedFlag"
              control={control}
              error={errors.bonusCo245gManureUsedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bonusCo229gManureUsedFlag" className="fw-normal">
              El: Bonus of 29 g CO2eq/MJ biofuel/bioliquid/biomass fuel if
              biomass is obtained from restored degraded land
            </Label>
            <CheckboxFlag
              id="bonusCo229gManureUsedFlag"
              name="bonusCo229gManureUsedFlag"
              control={control}
              error={errors.bonusCo229gManureUsedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="supplychainIncentiveReceivedFlag" className="fw-normal">
              For biogas supply chains: Were incentives/subsidies received for
              the production of the biogas?
            </Label>
            <CheckboxFlag
              id="supplychainIncentiveReceivedFlag"
              name="supplychainIncentiveReceivedFlag"
              control={control}
              error={errors.supplychainIncentiveReceivedFlag}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for="supplychainIncentiveReceivedName" className="fw-normal">
              If yes please specify
            </Label>
            <Input
              type="text"
              id="supplychainIncentiveReceivedName"
              data-test="supplychainIncentiveReceivedName"
              {...computeProps("supplychainIncentiveReceivedName")}
              invalid={!!errors.supplychainIncentiveReceivedName && !isDisabled}
              disabled={isDisabled}
              maxLength={100}
            />
            {errors.supplychainIncentiveReceivedName && (
              <FormFeedback>
                {errors.supplychainIncentiveReceivedName.message}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="nuts2Region" className="fw-normal">
              If applicable, please specify the NUTS2 Region
            </Label>
            <Input
              type="text"
              id="nuts2Region"
              data-test="nuts2Region"
              {...computeProps("nuts2Region")}
              invalid={!!errors.nuts2Region && !isDisabled}
              disabled={isDisabled}
              maxLength={100}
            />
            {errors.nuts2Region && (
              <FormFeedback>{errors.nuts2Region.message}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
      {selectedCountry !== COUNTRY_SPAIN && divisionCode !== DIV_CODE_SAF && (
        <>
          <FormGroup>
            <Label
              for="producerInstallationDateFlag"
              className="fw-normal mb-4"
            >
              The installation where the final biofuel or bioliquid was produced
              started physical production of biofuels or bioliquids after 5
              October 2015
            </Label>
            <CheckboxFlag
              id="producerInstallationDateFlag"
              name="producerInstallationDateFlag"
              control={control}
              error={errors.producerInstallationDateFlag}
              disabled={isDisabled}
              rules={{
                validate: (value) => {
                  return value !== null || "Please select an option";
                },
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="incentiveFlag" className="fw-normal mb-4">
              Were incentives/subsidies received for the production of the
              biogas?
            </Label>
            <CheckboxFlag
              id="incentiveFlag"
              name="incentiveFlag"
              control={control}
              error={errors.incentiveFlag}
              disabled={isDisabled}
              rules={{
                validate: (value) => {
                  return value !== null || "Please select an option";
                },
              }}
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  status: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  isDisabled: PropTypes.bool,
  divisionCode: PropTypes.string,
  fields: PropTypes.array,
  remove: PropTypes.func,
  isCheckedYes: PropTypes.bool,
  setIsCheckedYes: PropTypes.func,
  createAdditionalSplit: PropTypes.func,
  splitInputAsQty: PropTypes.bool,
  setSplitInputAsQty: PropTypes.func,
  useSplitMode: PropTypes.bool,
};
export default GHGEmissionInfo;
