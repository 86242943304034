import {Close} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";

import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import {rejectReasons} from "modules/DocManager/content/ValidationFields";

const RejectDocument = ({
  rejecting,
  handleRejectMessage,
  setSelectedOption,
  rejectModal,
  setRejectModal,
  divisionCode,
  onSubmit,
  submitLoading,
  setRejecting,
}) => {
  const {
    register,
    formState: {errors},
    control,
    watch,
    setError,
    getValues,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const handleSubmit = () => {
    const values = getValues();
    if (
      values?.rejectionReason &&
      values?.rejectionReasonNotes?.trim()?.length > 0
    ) {
      onSubmit("reject");
    } else {
      if (values.rejectionReason?.trim()?.length <= 0) {
        setError("rejectionReason", {
          type: "value",
          message: "Please select reject reason",
        });
      }
      if (values.rejectionReasonNotes?.trim()?.length <= 0) {
        setError("rejectionReasonNotes", {
          type: "value",
          message: "Please enter notes",
        });
      }
    }
  };

  return (
    <div>
      <Modal
        size="sm"
        isOpen={rejectModal}
        className="modal-dialog-centered mb-action-modal-344"
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-5 text-xl"
          close={
            <Close
              onClick={() => {
                setRejectModal(false);
                setRejecting(false);
              }}
            />
          }
        >
          Reject document
        </ModalHeader>
        <ModalBody className=" pt-0">
          <FormGroup>
            <Label for="rejectionReason" className="fw-light">
              Reason for rejection
            </Label>

            <Input
              type="select"
              id="rejectionReason"
              data-test="rejectionReason"
              {...computeProps("rejectionReason", {
                required: "Please select reject reason",
              })}
              invalid={!!errors.rejectionReason}
              maxLength={100}
              placeholder="Please select a reason"
              onChange={(e) => {
                handleRejectMessage(e.target.value);
                setSelectedOption(e.target.value);
              }}
            >
              <option value="">Please select a reason</option>

              {rejectReasons.length > 0 &&
                rejectReasons.map((p) => (
                  <option key={p.key} value={p.key}>
                    {p.value}
                  </option>
                ))}
            </Input>
            {errors.rejectionReason && (
              <FormFeedback>{errors.rejectionReason.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="rejectionReasonNotes" className="fw-normal mb-4">
              Notes
            </Label>
            <Input
              type="textarea"
              rows="3"
              id="rejectionReasonNotes"
              data-test="rejectionReasonNotes"
              {...computeProps("rejectionReasonNotes")}
              invalid={!!errors.rejectionReasonNotes}
              maxLength={200}
              disabled={!rejecting}
              placeholder=" Notes"
            />
            {errors.rejectionReasonNotes && (
              <FormFeedback>{errors.rejectionReasonNotes.message}</FormFeedback>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setRejectModal(false);
                  setRejecting(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !p-0 bg-transparent text-default whitespace-nowrap"
                type="button"
                onClick={() => handleSubmit()}
              >
                {submitLoading && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Confirm rejection
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

RejectDocument.propTypes = {
  rejecting: PropTypes.bool,
  handleRejectMessage: PropTypes.func,
  setSelectedOption: PropTypes.func,
  rejectModal: PropTypes.bool,
  setRejectModal: PropTypes.func,
  divisionCode: PropTypes.string,
  onSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  setRejecting: PropTypes.func,
};

export default RejectDocument;
