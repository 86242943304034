import moment from "moment";
import {parseToFloatNumber} from "../components/view-document/utils";

export const getViewDocumentDetailsSAFACCEPT = (
  data,
  splitDetails,
  emissionForTransport,
  account,
  divisionId,
  siteReferenceId,
  docId,
  dateFormat,
  document,
  decimalFormat,
  convertToNumber,
) => {
  return {
    divisionId,
    siteReferenceId,
    userAction: "ACCEPT",
    userId: account.username,
    rejectionReason: "",
    certificateInboundEuId: docId,

    replacePreviousVersion: data.replacePreviousVersion,
    previousVersionId: data.previousVersionId,
    additionalNotes: data.additionalNotes,
    documentData: {
      dateOfIssuance:
        data.dateOfIssuance === null || data.dateOfIssuance === ""
          ? null
          : moment(data.dateOfIssuance, dateFormat).format(
              "YYYY-MM-DD HH:mm:ss.0 ZZ",
            ),
      supplierName: data.supplierName,
      supplierAddress: data.supplierAddress,
      certificationSystem: data.certificationSystem,
      certificateNumber: data.certificateNumber,

      sdNumber: document?.sdNumber,
      sdNumberNew: data.sdNumber,
      sdNumberOld: document?.sdNumber,

      replacePreviousVersion: data.replacePreviousVersion,

      recipientName: data.recipientName,
      recipientAddress: data.recipientAddress,
      contractNumber: data.contractNumber,
      supplierDispatchAddress: data.supplierDispatchAddress,
      recipientReceiptAddress: data.recipientReceiptAddress,
      materialDispatchDatez:
        data.materialDispatchDatez === null || data.materialDispatchDatez === ""
          ? null
          : moment(data.materialDispatchDatez, dateFormat).format(
              "YYYY-MM-DD HH:mm:ss.0 ZZ",
            ),
      bioProductProducer: data.bioProductProducer,
      bioProductUser: data.bioProductUser,
      invoiceProviderName: data.invoiceProviderName,
      disaggregatedDefaultValueOilFlag: data.disaggregatedDefaultValueOilFlag,
      disaggregatedDefaultValueSoiln2oFlag:
        data.disaggregatedDefaultValueSoiln2oFlag,

      bonusCo229gManureUsedFlag: data.bonusCo229gManureUsedFlag,
      bonusCo245gManureUsedFlag: data.bonusCo245gManureUsedFlag,
      supplychainIncentiveReceivedFlag: data.supplychainIncentiveReceivedFlag,
      supplychainIncentiveReceivedName: data.supplychainIncentiveReceivedName,
      vesselAtHuelva: data.vesselAtHuelva,
      productType: data.productType,
      rawMaterialType: data.rawMaterialType,
      additionalInfo: data.additionalInfo,

      rawMaterialOriginCountryName: data.rawMaterialOriginCountryName,

      feedstockQty: convertToNumber(data.feedstockQty ?? "0", decimalFormat),
      feedstockQtyUom: data.feedstockQtyUom,

      productQty: convertToNumber(data.productQty ?? "0", decimalFormat),
      productQtyUom: data.productQtyUom,

      euRedCompliantFlag: data.euRedCompliantFlag,
      wasteOrAnimalByProductPermitNumber:
        data.wasteOrAnimalByProductPermitNumber,
      supportForFuelProductionOrFuelPrecursorReceivedFlag:
        data.supportForFuelProductionOrFuelPrecursorReceivedFlag,
      ifYesSpecifySupportNatureAndScheme:
        data.ifYesSpecifySupportNatureAndScheme,
      isccCompliantFlag: data.isccCompliantFlag,
      chainOfCustodyOption: data.chainOfCustodyOption,
      materialSustainabilityCriteriaFlag:
        data.materialSustainabilityCriteriaFlag,
      agriculturalBiomassIntermediateCropFlag:
        data.agriculturalBiomassIntermediateCropFlag,
      agriculturalBiomassLowRiskFlag: data.agriculturalBiomassLowRiskFlag,
      rawMaterialWasteOrResidueFlag: data.rawMaterialWasteOrResidueFlag,
      defaultValueAppliedFlag: data.defaultValueAppliedFlag,
      productEnergyContent: convertToNumber(
        data.productEnergyContent ?? "0",
        decimalFormat,
      ),
      nuts2Region: data.nuts2Region,
      rawMaterialCategory: data.rawMaterialCategory,
      deliveryQuantity: convertToNumber(
        data.deliveryQuantity ?? "0",
        decimalFormat,
      ),
      percentageCertifiedMaterial: convertToNumber(
        data.percentageCertifiedMaterial ?? "0",
        decimalFormat,
      ),
      recyclingOperationType: data.recyclingOperationType,
      wasteStatus: data.wasteStatus,
      mbOption: data.mbOption,
      multiSiteCreditTransferFlag: data.multiSiteCreditTransferFlag,
      japaneseDefaultValueCorn: data.japaneseDefaultValueCorn,
      emissionsFromLandUseCorn: data.emissionsFromLandUseCorn,
      japaneseDefaultValueSugarcane: data.japaneseDefaultValueSugarcane,
      emissionsFromLandUseSugarcane: data.emissionsFromLandUseSugarcane,
      ghgEmissionsRequirementFlag: data.ghgEmissionsRequirementFlag,
      consumablesFlag: data.consumablesFlag,
      nonGmoFoodFlag: data.nonGmoFoodFlag,
      nonGmoTechMarketFlag: data.nonGmoTechMarketFlag,
      electricityHeatFlag: data.electricityHeatFlag,
      motDistanceKm: parseToFloatNumber(data.motDistanceKm ?? "0"),
      ghgEmissionPercentageOut: parseToFloatNumber(
        data.ghgEmissionPercentageOut ?? "0",
      ),
      mbBalanceGroupId: data.mbBalanceGroupId,
      mbLocationGroupId: data.mbLocationGroupId,
      userInstallationDate:
        data.userInstallationDate === null || data.userInstallationDate === ""
          ? null
          : moment(data.userInstallationDate, dateFormat).format(
              "YYYY-MM-DD HH:mm:ss.0 ZZ",
            ),
      producerInstallationDate:
        data.producerInstallationDate === null ||
        data.producerInstallationDate === ""
          ? null
          : moment(data.producerInstallationDate, dateFormat).format(
              "YYYY-MM-DD HH:mm:ss.0 ZZ",
            ),
      ghgEec: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEec ?? "0", decimalFormat),
      ghgEl: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEl ?? "0", decimalFormat),
      ghgEp: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEp ?? "0", decimalFormat),
      ghgEtd: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEtd ?? "0", decimalFormat),
      ghgEu: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEu ?? "0", decimalFormat),
      ghgEsca: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEsca ?? "0", decimalFormat),
      ghgEccs: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEccs ?? "0", decimalFormat),
      ghgEccr: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEccr ?? "0", decimalFormat),
      ghgEee: data.defaultValueAppliedFlag
        ? 0
        : convertToNumber(data.ghgEee ?? "0", decimalFormat),
      ghgTotal: convertToNumber(data.ghgTotal ?? "0", decimalFormat),
      physicalReceiptDate:
        data.physicalReceiptDate === null || data.physicalReceiptDate === ""
          ? null
          : moment(data.physicalReceiptDate, dateFormat).format(
              "YYYY-MM-DD HH:mm:ss.0 ZZ",
            ),
      ghgEmissionPercentageIn: convertToNumber(
        data.ghgEmissionPercentageIn ?? "0",
        decimalFormat,
      ),

      transportationEmissionId: data.transportationEmissionId,
      transportationEmissionRoute: data.transportationEmissionRoute,

      splitMode: data.splitInputByQty ? 0 : 1,
      splitDetails: splitDetails?.map((split) => ({
        ...split,
        splitPercentage: convertToNumber(
          split.splitPercentage ?? "0",
          decimalFormat,
        ),
        feedstockQty: convertToNumber(split.feedstockQty ?? "0", decimalFormat),
        mbLocation: data.mbLocationGroupId,
        physicalReceiptDate:
          split.physicalReceiptDate === null || split.physicalReceiptDate === ""
            ? null
            : moment(split.physicalReceiptDate, dateFormat).format(
                "YYYY-MM-DD HH:mm:ss.0 ZZ",
              ),
        transportationEmissionId: emissionForTransport
          ? data.transportationEmissionId
          : null,
        transportationEmissionRoute: emissionForTransport
          ? data.transportationEmissionRoute
          : null,
        defaultValueAppliedFlag: split.defaultValueAppliedFlag,
        ghgEec: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEec ?? "0", decimalFormat),
        ghgEl: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEl ?? "0", decimalFormat),
        ghgEp: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEp ?? "0", decimalFormat),
        ghgEtd: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEtd ?? "0", decimalFormat),
        ghgEu: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEu ?? "0", decimalFormat),
        ghgEsca: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEsca ?? "0", decimalFormat),
        ghgEccs: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEccs ?? "0", decimalFormat),
        ghgEccr: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEccr ?? "0", decimalFormat),
        ghgEee: split.defaultValueAppliedFlag
          ? 0
          : convertToNumber(split.ghgEee ?? "0", decimalFormat),
        ghgTotal: convertToNumber(split.ghgTotal ?? "0", decimalFormat),
      })),
    },
  };
};

export const getViewDocumentDetailsREJECT = (
  data,
  splitDetails,
  emissionForTransport,
  account,
  divisionId,
  siteReferenceId,
  docId,
) => {
  return {
    divisionId,
    siteReferenceId,

    userId: account.username,
    certificateInboundEuId: docId,
    rejectionReason: data.rejectionReason,
    replacePreviousVersion: false,
    additionalNotes: data.rejectionReasonNotes,
    userAction: "REJECT",
  };
};

export default {getViewDocumentDetailsSAFACCEPT, getViewDocumentDetailsREJECT};
