import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const SAF_DOCUMENT_DETAIL_VIEW_NEW = gql`
  query GetDetailView(
    $divisionId: String
    $siteReferenceId: String
    $certificateInboundEuId: String
  ) {
    bioLcGetMaterialDataApi(event: {divisionId: $divisionId}) {
      error
      productNames {
        productName
      }
      rawMaterialNames {
        rawMaterialName
      }
      statusCode
    }

    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }
    bioLcCoproConfigCenterTransportationEmission {
      Records {
        transportation_emission_id
        sending_location_name
        sending_location_code
        receiving_location_name
        receiving_location_code
        mot_name
      }
    }

    bioLcGetIncomingDocFileApi(
      event: {
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        certificateInboundEuId: $certificateInboundEuId
      }
    ) {
      downloadUrl
      error
      statusCode
    }

    bioLcGetIncomingDocDetailsApi(
      event: {
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        certificateInboundEuId: $certificateInboundEuId
      }
    ) {
      error
      statusCode
      documentDetails {
        additionalInfo
        additionalNotes
        agriculturalBiomassIntermediateCropFlag
        agriculturalBiomassLowRiskFlag
        disaggregatedDefaultValueSoiln2oFlag
        disaggregatedDefaultValueOilFlag
        bonusCo229gManureUsedFlag
        bonusCo245gManureUsedFlag
        supplychainIncentiveReceivedFlag
        supplychainIncentiveReceivedName
        invoiceProviderName
        vesselAtHuelva
        allocatedElectricity
        allocatedHeat
        rawMaterialCategory
        deliveryQuantity
        percentageCertifiedMaterial
        recyclingOperationType
        wasteStatus
        mbOption
        multiSiteCreditTransferFlag

        userInstallationDate
        supplierName
        supplierDispatchAddress
        supplierAddress
        state
        sdNumber
        recipientReceiptAddress
        recipientName
        recipientAddress
        rawMaterialType
        rawMaterialWasteOrResidueFlag
        rawMaterialOriginCountryName
        productType
        productQtyUom
        productQty
        productEnergyContent
        producerInstallationDate
        previousVersionSdNumber
        previousVersionId
        nuts2Region
        materialSustainabilityCriteriaFlag
        materialDispatchDatez
        isccCompliantFlag

        ghgTotal
        ghgEu
        ghgEtd
        ghgEsca
        ghgEp
        ghgEmissionPercentageIn
        ghgEl
        ghgEec
        ghgEee
        ghgEccs
        ghgEccr
        feedstockQtyUom
        feedstockQty
        japaneseDefaultValueCorn
        emissionsFromLandUseCorn
        japaneseDefaultValueSugarcane
        emissionsFromLandUseSugarcane

        ghgEmissionsRequirementFlag
        euRedCompliantFlag
        defaultValueAppliedFlag
        dateOfIssuance
        consumablesFlag
        nonGmoFoodFlag
        nonGmoTechMarketFlag
        electricityHeatFlag

        contractNumber
        chainOfCustodyOption
        certificationSystem
        certificateNumber
        certificateInboundEuId
        bioProductUser
        bioProductProducer
        bvAuditChangedDatez
        bvAuditCreatedDatez
        recipientReceiptAddressSameFlag
        supplierDispatchAddressSameFlag
        physicalReceiptDate
        transportationEmissionId
        transportationEmissionRoute

        wasteOrAnimalByProductPermitNumber
        supportForFuelProductionOrFuelPrecursorReceivedFlag
        ifYesSpecifySupportNatureAndScheme

        splitMode
        splitDetails {
          defaultValueAppliedFlag
          feedstockQty
          splitPercentage
          physicalReceiptDate
          originCountryName
          ghgEccr
          ghgEccs
          ghgEee
          ghgEec
          ghgEl
          ghgEp
          ghgEsca
          ghgEtd
          ghgEu
          ghgTotal
          transportationEmissionId
          transportationEmissionRoute
        }

        mbBalanceGroupId
        mbLocationGroupId
      }
    }

    bioLcGetLocationGroupApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      locationGroups {
        locationGroupName
        locationGroupId
      }
      statusCode
      error
    }

    bioLcGetBalanceGroupApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      balanceGroups {
        balanceGroupName
        balanceGroupId
      }
      statusCode
      error
    }

    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcGetVersioningDocsApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      documents {
        sdNumber
        certificateInboundEuId
      }
      statusCode
      error
    }
  }
`;

export const DOCUMENT_DETAIL_VIEW = gql`
  query GetDetailView(
    $divisionId: String
    $siteReferenceId: String
    $certificateInboundEuId: String
  ) {
    bioLcGetMaterialDataApi(event: {divisionId: $divisionId}) {
      error
      productNames {
        productName
      }
      rawMaterialNames {
        rawMaterialName
      }
      statusCode
    }

    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
      }
    }
    bioLcCoproConfigCenterTransportationEmission {
      Records {
        transportation_emission_id
        sending_location_name
        sending_location_code
        receiving_location_name
        receiving_location_code
        mot_name
      }
    }

    bioLcGetIncomingDocFileApi(
      event: {
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        certificateInboundEuId: $certificateInboundEuId
      }
    ) {
      downloadUrl
      error
      statusCode
    }

    bioLcGetIncomingDocDetailsApi(
      event: {
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        certificateInboundEuId: $certificateInboundEuId
      }
    ) {
      error
      statusCode
      documentDetails {
        additionalInfo
        additionalNotes
        agriculturalBiomassIntermediateCropFlag
        agriculturalBiomassLowRiskFlag
        disaggregatedDefaultValueSoiln2oFlag
        disaggregatedDefaultValueOilFlag
        bonusCo229gManureUsedFlag
        bonusCo245gManureUsedFlag
        supplychainIncentiveReceivedFlag
        supplychainIncentiveReceivedName
        invoiceProviderName
        vesselAtHuelva
        allocatedElectricity
        allocatedHeat

        userInstallationDate
        supplierName
        supplierDispatchAddress
        supplierAddress
        state
        sdNumber
        recipientReceiptAddress
        recipientName
        recipientAddress
        rawMaterialType
        rawMaterialWasteOrResidueFlag
        rawMaterialOriginCountryName
        productType
        productQtyUom
        productQty
        productEnergyContent
        producerInstallationDate
        previousVersionSdNumber
        previousVersionId
        nuts2Region
        materialSustainabilityCriteriaFlag
        materialDispatchDatez
        isccCompliantFlag

        ghgTotal
        ghgEu
        ghgEtd
        ghgEsca
        ghgEp
        ghgEmissionPercentageIn
        ghgEl
        ghgEec
        ghgEee
        ghgEccs
        ghgEccr
        feedstockQtyUom
        feedstockQty

        euRedCompliantFlag
        defaultValueAppliedFlag
        dateOfIssuance

        contractNumber
        chainOfCustodyOption
        certificationSystem
        certificateNumber
        certificateInboundEuId
        bioProductUser
        bioProductProducer
        bvAuditChangedDatez
        bvAuditCreatedDatez
        recipientReceiptAddressSameFlag
        supplierDispatchAddressSameFlag
        physicalReceiptDate
        transportationEmissionId
        transportationEmissionRoute

        wasteOrAnimalByProductPermitNumber
        supportForFuelProductionOrFuelPrecursorReceivedFlag
        ifYesSpecifySupportNatureAndScheme

        splitDetails {
          defaultValueAppliedFlag
          feedstockQty
          physicalReceiptDate
          originCountryName
          ghgEccr
          ghgEccs
          ghgEee
          ghgEec
          ghgEl
          ghgEp
          ghgEsca
          ghgEtd
          ghgEu
          ghgTotal
          transportationEmissionId
          transportationEmissionRoute
        }

        mbBalanceGroupId
        mbLocationGroupId
      }
    }

    bioLcGetLocationGroupApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      locationGroups {
        locationGroupName
        locationGroupId
      }
      statusCode
      error
    }

    bioLcGetBalanceGroupApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      balanceGroups {
        balanceGroupName
        balanceGroupId
      }
      statusCode
      error
    }

    bioLcCoproEuGetCountries {
      code
      name
    }

    bioLcGetVersioningDocsApi(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      documents {
        sdNumber
        certificateInboundEuId
      }
      statusCode
      error
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocumentManagerMutation($event: UpdateIncomingDocRequest!) {
    bioLcUpdateIncomingDocDataApi(event: $event) {
      error
      errors {
        field
        message
      }
      statusCode
    }
  }
`;

export const docManagerUpdate = (data) => {
  return client.mutate({
    mutation: UPDATE_DOCUMENT,
    variables: {
      ...data,
    },
  });
};

export default {DOCUMENT_DETAIL_VIEW};
